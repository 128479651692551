import React from 'react';
import styled from 'styled-components';
import SEO from '../components/SEO';
import Container from '../styles/Container';

const Styled404Page = styled.div`
  text-align: center;
  img {
    width: 100%;
    height: 500px;
    object-fit: contain;
  }
`;

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <Styled404Page>
      <Container>
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <img
          src="https://media.giphy.com/media/JYZ397GsFrFtu/source.gif"
          alt="Michael Scott shouting noooooooo"
        />
      </Container>
    </Styled404Page>
  </>
);

export default NotFoundPage;
